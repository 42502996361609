@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Mono&display=swap");
/* Ajoutez ces classes de style dans votre feuille de style CSS */

.result-price {
  background: white;
  padding: 6px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.price_sim {
  font-family: "DM Mono", monospace;
}

.shadow {
  box-shadow: -1px 1px 26px -16px rgba(0, 0, 0, 0.35);
}

.leftForm,
.rightForm {
  width: 49%;
}

.gap-20 {
  gap: 90px;
}

.svg-inline--fa {
  font-size: 17px !important;
  color: #e50086;
}

.fa-circle-arrow-right {
  font-size: 17px !important;
  color: white;
  margin-left: 10px;
}

.strike {
  display: flex;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: flex;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: lightgrey;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.form-container {
  max-height: 100vh;
  overflow-y: auto;
}
